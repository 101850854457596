import axios from "axios";
const { rootUrl } = require('../../config/config');
const token = localStorage.getItem('authToken') || 'default-token';

class Commons {
    async getStoredCalendarWeek() {
        try {
            const response = await axios.get(`${rootUrl}/system/system_week`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            console.log(response.data); // Print the response data
            return response.data.value; // Return the data if needed
        } catch (error) {
            console.error('Error fetching stored calendar week:', error);
        }
    }
}

export default Commons;