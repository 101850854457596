import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const TransactionDetails = () => {
  const { plate_no, doc_no } = useParams(); // Get plate_no and doc_no from the URL
  const { rootUrl } = require('../../config/config');
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Hook to navigate to previous pages

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const token = localStorage.getItem("authToken") || "defaultToken";
        const response = await axios.get(
          `${rootUrl}/cars/${plate_no}/trans`, // Fetch all transactions for the car
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // Filter the response to get the specific transaction by doc_no
        const filteredTransaction = response.data.find(
          (t) => t.doc_no === doc_no
        );

        if (filteredTransaction) {
          setTransaction(filteredTransaction);
        } else {
          setError("Transaction not found.");
        }
      } catch (err) {
        console.error("Error fetching transaction:", err);
        setError("Failed to fetch transaction details.");
      } finally {
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [plate_no, doc_no]);

  if (loading) {
    return <div className="text-center text-gray-500 py-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-10">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      
      <h1 className="text-2xl font-bold text-gray-700 mb-6 border-b pb-2">
        Transaction Details
      </h1>

      {transaction ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600"><strong>ID:</strong> {transaction.id}</p>
            <p className="text-gray-600"><strong>Document Number:</strong> {transaction.doc_no}</p>
            <p className="text-gray-600"><strong>Plate Number:</strong> {transaction.plate_no}</p>
            <p className="text-gray-600"><strong>Driver ID:</strong> {transaction.driver_id}</p>
          </div>
          <div>
            <p className="text-gray-600"><strong>Type:</strong> {transaction.type}</p>
            <p className="text-gray-600"><strong>Description:</strong> {transaction.short_desc}</p>
            <p className="text-gray-600">
              <strong>Date:</strong> {new Date(transaction.date).toLocaleDateString()}
            </p>
          </div>
          <div>
            <p className="text-gray-600"><strong>Debit:</strong> {transaction.debit}</p>
            <p className="text-gray-600"><strong>Credit:</strong> {transaction.credit}</p>
            <p className="text-gray-600"><strong>Amount:</strong> {transaction.amount}</p>
          </div>
          <div>
            <p className="text-gray-600"><strong>Notes:</strong> {transaction.notes}</p>
          </div>
        </div>
      ) : (
        <p className="text-gray-500">Transaction not found.</p>
      )}
    </div>
  );
};

export default TransactionDetails;
