import React, { useState, useEffect } from 'react';
import { FaBell, FaCheckCircle, FaFilter, FaTrash } from 'react-icons/fa';

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([
    { id: 1, message: 'New admin message', time: '2 minutes ago', read: false, category: 'System' },
    { id: 2, message: 'Driver assignment updated', time: '30 minutes ago', read: false, category: 'Updates' },
    { id: 3, message: 'Inspection due tomorrow', time: '1 hour ago', read: true, category: 'Reminders' },
  ]);

  const [filter, setFilter] = useState('All');

  useEffect(() => {
    // Simulated API call or WebSocket to fetch real notifications
  }, []);

  const handleMarkAsRead = (id) => {
    setNotifications((prevState) =>
      prevState.map((notif) => (notif.id === id ? { ...notif, read: true } : notif))
    );
  };

  const handleDeleteNotification = (id) => {
    setNotifications((prevState) => prevState.filter((notif) => notif.id !== id));
  };

  const filteredNotifications =
    filter === 'All'
      ? notifications
      : notifications.filter((notif) => notif.category === filter);

  return (
    <div className="max-w-5xl mx-auto p-6">
      {/* Page Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-semibold text-gray-800">Notifications</h2>
        <div className="flex items-center space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="border border-gray-300 rounded-md text-sm p-2 text-gray-600"
          >
            <option value="All">All</option>
            <option value="System">System</option>
            <option value="Updates">Updates</option>
            <option value="Reminders">Reminders</option>
          </select>
          <button
            className="text-blue-500 hover:text-blue-700 font-medium"
            onClick={() => setNotifications((prevState) => prevState.map((notif) => ({ ...notif, read: true })))}
          >
            Mark All as Read
          </button>
        </div>
      </div>

      {/* Notifications List */}
      <div className="space-y-4">
        {filteredNotifications.map((notification) => (
          <div
            key={notification.id}
            className={`flex items-start p-4 rounded-lg shadow-md transition-all duration-300 
              ${notification.read ? 'bg-gray-100' : 'bg-white hover:bg-blue-50'}`}
          >
            <div className="flex-shrink-0">
              <FaBell className={`text-2xl ${notification.read ? 'text-gray-400' : 'text-blue-600'}`} />
            </div>
            <div className="ml-4 flex-1">
              <p className="text-lg font-medium text-gray-800">{notification.message}</p>
              <span className="text-sm text-gray-500">{notification.time}</span>
              <span className="ml-2 text-xs text-gray-500 italic">({notification.category})</span>
            </div>
            <div className="flex items-center space-x-3">
              {!notification.read && (
                <button
                  onClick={() => handleMarkAsRead(notification.id)}
                  className="text-green-500 hover:text-green-700"
                  aria-label="Mark as read"
                >
                  <FaCheckCircle />
                </button>
              )}
              <button
                onClick={() => handleDeleteNotification(notification.id)}
                className="text-red-500 hover:text-red-700"
                aria-label="Delete notification"
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}

        {/* Empty State */}
        {filteredNotifications.length === 0 && (
          <div className="text-center text-gray-500">
            <p>No notifications available for this filter.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
