import React from 'react';
import { Link } from 'react-router-dom';
import { FaCog, FaBell, FaUserCircle } from 'react-icons/fa';
import logoUrl from './logo.jpg';
import { useAuth } from './AuthContext'; // Adjust the path as needed

const Header = () => {
  // Fetch user data from AuthContext
  const { user } = useAuth();

  return (
    <header className="bg-gradient-to-r from-blue-700 via-blue-800 to-blue-900 shadow-md text-white fixed top-0 left-0 right-0 z-50 h-16">
      <div className="flex justify-between items-center px-4 sm:px-6 py-2">
        {/* Branding Section */}
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img
              src={logoUrl}
              alt="Company Logo"
              className=" ml-5 w-10 h-10 rounded-full object-cover"
            />
          </Link>
          <h1 className="hidden sm:block text-lg sm:text-2xl font-bold tracking-wide">
            Cosmo Fleets
          </h1>
        </div>

        {/* Actions Section */}
        <div className="flex items-center space-x-4 sm:space-x-6">
          {/* Notifications */}

          <Link
  to="/notifications"
  className="relative p-2 rounded-full hover:bg-blue-700 transition"
  aria-label="Notifications"
>
  <FaBell className="text-lg sm:text-xl" />
  <span className="absolute top-1 right-1 bg-red-500 text-xs sm:text-sm text-white rounded-full px-1">
    3
  </span>
</Link>


          {/* Settings */}
          <Link
            to="/cosmosettings"
            className="p-2 rounded-full hover:bg-blue-700 transition"
            aria-label="Settings"
          >
            <FaCog className="text-lg sm:text-xl" />
          </Link>

          {/* User Profile */}
          <div className="flex items-center space-x-2">
            <FaUserCircle className="text-2xl sm:text-3xl" />
            <span className="hidden sm:inline-block font-medium">
              {user ? user.username : ''}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
