import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';



const  MyCarsApp = () => {
    const { rootUrl } = require('../../config/config');
    const [cars, setCars] = useState([]); 
    const { isAuthenticated } = useAuth();
    const [drivers, setDrivers] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');   
    const [searchTerm, setSearchTerm] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [searchBy, setSearchBy] = useState('plate_no');
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [selectedDriverId, setSelectedDriverId] = useState('');
    const [selectedDriverName, setSelectedDriverName] = useState('');
    const [showAddCarForm, setShowAddCarForm] = useState(false);
    const [newCar, setNewCar] = useState({
        plate_no: '',
        model: '',
        make: '',
        insurer: '',
        insurer_expiry: '',
        year:'',
        status:'',
        drive_link:'',
        driver_name:'',
        driver_id: '',
        input_by:'' ,
        input_date:'' ,
        edit_by:'',
        edit_date:''
        
      
    });
    const [errors, setErrors] = useState({
        plate_no: '',
        model: '',
        make: '',
        insurer: '',
        insurer_expiry: '',
        year: '',
        status:'',
        drive_link:'',
        driver_name:'',
        driver_id: '',
        input_by:'',
        input_date:'',
        edit_by:'',
        edit_date:''
    });

   
    
    const [editingCar, setEditingCar] = useState(null);
    const [viewingCar, setViewingCar] = useState(null);

    useEffect(() => {
        console.log('User:', user); // Check user information
        if (user && user.username) {
            const currentDate = new Date().toISOString(); // Get current date in ISO format
            setNewCar((prevDriver) => ({
                ...prevDriver,
                input_by: user.username,
                input_date: currentDate,
                edit_by: user.username,
                edit_date: currentDate,
            }));
        }
    }, [user]);

    useEffect(() => {
        const fetchCars = async () => {
              const token = localStorage.getItem('authToken')|| 'default-token';
            try {
                const [carResponse, driverResponse] = await Promise.all([
                    axios.get(`${rootUrl}/cars`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }),
                    axios.get(`${rootUrl}/drivers`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    })
                ]);
                
                setDrivers(driverResponse.data);

                const carData = carResponse.data

                const mappedData = carData.map(item => ({   
                    plate_no: item.plate_no || 'No sender name', 
                    model: item.model || 'unknown',
                    make: item.make || 'N/A',
                    insurer: item.insurer|| 'N/A',                   
                    insurer_expiry: item.insurer_expiry ? item.insurer_expiry.split('T')[0] : 'N/A', // Ensure it's just the date
                    year: item.year|| 'no Car make',
                    bal: item.bal || '0.00',
                    actual_bal: item.actual_bal || '0.00',
                    status: item.status || 'no status!',
                    drive_link :item.drive_link || 'no drive link', 
                    driver_name:item.driver_name || 'no drivers name',
                    driver_id: item.driver_id || 'no id provided',
                    input_by: item.input_by || 'sorry!',
                    edit_by: item.edit_by || "N/A" ,
                    edit_date: item.edit_date || "N/A"

                    
                }));

                setCars(mappedData);
                console.log('car data', mappedData)

                const total = mappedData.reduce((acc, car) => acc + car.bal, 0);
                setTotalAmount(total);
   

            } catch (error) {
                console.error("Error fetching cars: ", error);
            }
            
        };

        fetchCars();
    }, []);
    

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };

    
  
    

    

    const handleAddCar = async () => {
        let newErrors = {};
    
        // Validation checks
        if (!newCar.plate_no) newErrors.plate_no = 'Plate Number is Required!';
        if (!newCar.model) newErrors.model = 'Car Text is Required!';
        if (!newCar.make) newErrors.make = 'Car Make is Required!';
        if (!newCar.year) newErrors.year = 'Year Make is Required!';
        if (!newCar.status) newErrors.status = 'Car Status is Required!';
        if (!newCar.drive_link) newErrors.drive_link = 'Provide drive link!';   
        if (!newCar.input_by) newErrors.input_by = 'Fill in user!';
        if (!newCar.input_date) newErrors.input_date = 'Fill in input date';
        if (!newCar.driver_name) newErrors.driver_name = 'Fill in driver name!';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            console.log("Form validation errors: ", newErrors);
            return;
        }
    
        try {
            const timestamp = new Date().toISOString();
            const carData = {
                plate_no: newCar.plate_no,
                model: newCar.model,
                make: newCar.make,
                insurer: newCar.insurer,
                insurer_expiry: newCar.insurer_expiry || null,
                year: newCar.year,
                status: newCar.status,
                drive_link: newCar.drive_link,
                driver_name: selectedDriverName,
                driver_id: newCar.driver_id,
                input_by: user.username,
                input_date: timestamp,
            };
    
            console.log('Preparing to send car data:', carData);
    
            const response = await axios.post(`${rootUrl}/cars`, carData);
            console.log('Server response:', response.data);
    
            if (response.status === 201 || response.status === 200) {
                const addedCar = { ...carData, insurer_expiry: carData.insurer_expiry || null };
                setCars([...cars, addedCar]);
                setShowAddCarForm(false);
                setNewCar({
                    plate_no: '',
                    model: '',
                    make: '',
                    insurer: '',
                    insurer_expiry: '',
                    year: '',
                    status: '',
                    drive_link: '',
                    driver_name: '',
                    driver_id: '',
                    input_by: user.username,
                    input_date: timestamp,
                });
    
                setSuccessMessage('Car added successfully!');
                setTimeout(() => setSuccessMessage(''), 6000);
            } else {
                console.error("Unexpected server response status:", response.status);
                setErrors({ form: "Unexpected server response. Please try again." });
            }
        } catch (error) {
            console.error("Error during axios request:", error);
            setErrors({ form: "An error occurred. Please try again later." });
        }
    };
    
    
    
    
    
    const handleEditCar = (plate_no) => {
        const carToEdit = cars.find(car => car.plate_no === plate_no);
    
        if (carToEdit) {
            const formattedDate = carToEdit.insurer_expiry
                ? new Date(carToEdit.insurer_expiry).toISOString().split('T')[0] 
                : ""; 
    
            setEditingCar({ ...carToEdit, insurer_expiry: formattedDate });
        }
    };
    
    const handleUpdateCar = async () => {
        if (!editingCar) return;
    
        let newErrors = {};
    
      
        if (!editingCar.plate_no || editingCar.plate_no.length !== 7) newErrors.plate_no = 'Plate number must be exactly 8 digits';
        if (!editingCar.model) newErrors.model = 'Model is required';
        if (!editingCar.make) newErrors.make = 'Make is required';
        if (!editingCar.year) newErrors.year = 'Year is required';
        if (!editingCar.status) newErrors.status = 'Status is required';
        if (!editingCar.driver_name) newErrors.driver_name = 'Driver Name is required';
        if (!editingCar.driver_id)newErrors.driver_id = 'driver id is required'
        if (!editingCar.drive_link)newErrors.driver_id = 'drive link is required'
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        
    
       
        const { plate_no, model, make, insurer, status, insurer_expiry, year, driver_name, driver_id, drive_link } = editingCar;
    
        let formattedDate = null;
    
        
        if (insurer_expiry) {
            if (isNaN(Date.parse(insurer_expiry))) {
                setErrors((prevErrors) => ({ ...prevErrors, insurer_expiry: 'Invalid date value' }));
                return;
            }
            formattedDate = new Date(insurer_expiry).toISOString().split('T')[0]; 
        }

        const edit_by = user.username; // replace with actual username or ID
        const edit_date = new Date().toISOString(); // current date and time in ISO format
    
        try {
            
            await axios.put(`${rootUrl}/cars/${plate_no}`, {
                plate_no, model, make, insurer, year, status, driver_name, driver_id, drive_link, insurer_expiry: formattedDate, edit_by, edit_date 
            });
    
            
            const updatedCars = cars.map(car =>
                car.plate_no === plate_no
                    ? { ...car, plate_no, model, make, insurer, insurer_expiry: formattedDate, year, status, driver_name, driver_id , drive_link, edit_by, edit_date }
                    : car
            );
            setCars(updatedCars);
            setEditingCar(null); 
        } catch (error) {
            console.error("Error updating car: ", error);
        }
    };
     
    
    
    
    const handleDeleteCar = async (plate_no) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this car?');
    
        if (!isConfirmed) {
           
            return;
        }
    
        try {
            await axios.delete(`${rootUrl}/cars/${plate_no}`);
            const updatedCars = cars.filter(car => car.plate_no !== plate_no);
            setCars(updatedCars);
        } catch (error) {
            console.error("Error deleting car: ", error);
        }
    };
    

    
    const handleNewCarDriverNameChange = (e) => {
        const selectedName = e.target.value;
        const selectedDriver = drivers.find(driver => driver.name === selectedName);
    
        setNewCar(prevState => ({
            ...prevState,
            driver_name: selectedName,
            driver_id: selectedDriver ? selectedDriver.id_no : ''
        }));
    };
    
    const handleEditingCarDriverNameChange = (e) => {
        const selectedName = e.target.value;
        const selectedDriver = drivers.find(driver => driver.name === selectedName);
    
        setEditingCar(prevState => ({
            ...prevState,
            driver_name: selectedName,
            driver_id: selectedDriver ? selectedDriver.id_no : ''
        }));
    };

    const handleViewCar = (plate_no) => {
        // Navigate to the transaction page for this car
        navigate(`/transactions/${plate_no}`);
    };

    const handleRefreshBalances = async() => {
        try{
            const Response = await axios.post(`${rootUrl}/cars/refresh`);
            alert("balances refreshed successfully")
        }catch(error){
            console.error('error refreshing balances:', error);
            alert("failed to refresh balances")
        }


    };
    
    

    const filteredCars = cars.filter(car => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        switch (searchBy) {
            case 'plate_no':
                return car.plate_no.toLowerCase().includes(lowerCaseSearchTerm);
            case 'model':
                return car.model.toLowerCase().includes(lowerCaseSearchTerm);
            case 'make':
                return car.make.toLowerCase().includes(lowerCaseSearchTerm);
            case 'insurer':
                 return car.insurer.toLowerCase().includes(lowerCaseSearchTerm);
             case 'year':
                return car.year.toString().toLowerCase().includes(lowerCaseSearchTerm.toString().toLowerCase());
             case 'status':
                 return car.status.toLowerCase().includes(lowerCaseSearchTerm);    
            case 'insurer_expiry':                
                return new Date(car.timestamp).toLocaleString().toLowerCase().includes(lowerCaseSearchTerm);
            case 'driver_name':
                return car.driver_name.toLowerCase().includes(lowerCaseSearchTerm);
            default: 
               return false;
 
        }
    });

    const totalFilteredAmount = filteredCars.reduce(
        (total, car) => total + Number(car.bal || 0),
        0
    );
    
    //console.log("Total Filtered Amount:", totalFilteredAmount);

    
    const carCount = filteredCars.length;

    return (
        <div className={`min-h-screen ${theme}`}>
            <div className="w-full p-0 sm:p-4">
                <h2 className="text-center text-xl md:text-2xl font-bold mb-4">Cars Onboard</h2>
    
                {successMessage && (
                    <div className="bg-green-300 text-center text-green-800 p-2 rounded mb-4">
                        {successMessage}
                    </div>
                )}
    
                {/* Search and Filter Section */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-2 md:space-y-0">
                    <div>
                        <label className="mr-2">Search By:</label>
                        <select
                            className="border rounded px-2 py-1 text-xs md:text-sm w-full md:w-auto"
                            value={searchBy}
                            onChange={handleSearchByChange}
                        >
                            <option value="plate_no">Plate Number</option>
                            <option value="model">Model</option>
                            <option value="make">Make</option>
                            <option value="insurer">Insurer</option>
                            <option value="insurer_expiry">Insurer Expiry</option>
                            <option value="year">Year Make</option>
                            <option value="status">Status</option>
                            <option value="driver_name">Driver Name</option>
                        </select>
                    </div>
    
                    <input
                        type="text"
                        placeholder={`Search by ${searchBy}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="border rounded px-2 py-1 w-full md:w-1/3 text-xs md:text-sm"
                    />
                    <div classname='mr-2'>

                    <button
                        className="bg-blue-500 mr-5 hover:bg-blue-700 text-white px-4 py-1 rounded text-xs md:text-sm w-full md:w-auto"
                        onClick={handleRefreshBalances}
                       
                    >
                        Refresh balances
                    </button>
    
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-1 rounded text-xs md:text-sm w-full md:w-auto"
                        onClick={() => setShowAddCarForm(true)}
                    >
                        New Car
                    </button>
                    </div>
                </div>

                <div className="flex justify-between bg-slate-300 items-center mb-4">
                <p>Total Cars: {carCount}</p>
                <p className="text-right">Total Amount: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(totalFilteredAmount)}</p>
            </div>
    
                {/* Table Section */}
                <div className="overflow-x-auto">
                    <table className="min-w-full border-collapse">
                        <thead>
                            <tr className="bg-slate-800 text-white text-xs md:text-sm">
                                <th className="border px-2 py-1 text-left">Plate</th>
                                <th className="border px-2 py-1 text-left hidden md:table-cell">Model</th>
                                <th className="border px-2 py-1 text-left hidden lg:table-cell">Make</th>
                                <th className="border px-2 py-1 text-left hidden lg:table-cell">Insurer</th>
                                <th className="border px-2 py-1 text-left hidden xl:table-cell">Expiry</th>
                                <th className="border px-2 py-1 text-left">Status</th>
                                <th className="border px-2 py-1 text-right">Balance</th>
                                <th className="border px-2 py-1 text-left hidden md:table-cell">Driver</th>
                                <th className="border px-2 py-1 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCars.map((car, index) => (
                                <tr key={car.plate_no} className={`${index % 2 === 0 ? "bg-white" : "bg-slate-200"} text-xs md:text-sm`}>
                                    <td className="border px-2 py-1">{car.plate_no}</td>
                                    <td className="border px-2 py-1 hidden md:table-cell">{car.model}</td>
                                    <td className="border px-2 py-1 hidden lg:table-cell">{car.make}</td>
                                    <td className="border px-2 py-1 hidden lg:table-cell">{car.insurer}</td>
                                    <td
                                        className={`border px-2 font-bold py-1 hidden xl:table-cell ${
                                         car.insurer_expiry &&
                                         new Date(car.insurer_expiry) <= new Date(new Date().setMonth(new Date().getMonth() + 1))
                                           ? 'text-red-600'
                                           : 'text-blue-700'
                                           }`}
                                           >
                                           {car.insurer_expiry || 'N/A'}
                                    </td>

                                    <td className="border px-2 py-1">{car.status}</td>
                                    <td
                                            className={`border text-right px-2 py-1 ${
                                               car.actual_bal > 0 ? 'text-red-500' : 'text-green-500'
                                              }`}
>
                                            {car.actual_bal < 0
                                             ? `(${new Intl.NumberFormat('en-US', {
                                             style: 'currency',
                                             currency: 'KES',
                                                }).format(Math.abs(car.actual_bal))})`
                                            : new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                             currency: 'KES',
                                            }).format(car.actual_bal)} 
                                    </td>

                                    <td className="border px-2 py-1 hidden md:table-cell">{car.driver_name}</td>
                                    <td className="border px-2 py-1 flex space-x-1 justify-center">
                                        <button
                                            className="bg-slate-400 text-white p-1 rounded"
                                            onClick={() => handleEditCar(car.plate_no)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button
                                            className="bg-green-700 text-white p-1 rounded"
                                            onClick={() => handleViewCar(car.plate_no)}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </button>
                                        <button
                                            className="bg-red-700 text-white p-1 rounded"
                                            onClick={() => handleDeleteCar(car.plate_no)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                  
                </div>
            </div>
       
    
      

            {showAddCarForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-auto">
                        <h2 className="text-xl font-bold  mb-4">Add Car</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-2">
                           <label>Plate Number</label>
                            <input
                            type="text"
                            className="border rounded w-full px-2 py-1"
                            value={newCar.plate_no}
                            onChange={(e) => {
                            let plate_noValue = e.target.value.toUpperCase(); 
           
                             if (plate_noValue.startsWith('K')) {
              
                            if (/^[A-Z0-9\s]*$/.test(plate_noValue) && plate_noValue.length <= 7) {
                             setNewCar({ ...newCar, plate_no: plate_noValue });

                            if (plate_noValue.length === 7) {
                             setErrors((prevErrors) => ({ ...prevErrors, plate_no: '' }));
                             } 
                            }
                            } else {
                            setErrors((prevErrors) => ({ ...prevErrors, plate_no: 'Plate no start with "K" & must be 8 character' }));
                             }
                            }}
                         />
                           {errors.plate_no && <p className="text-red-500">{errors.plate_no}</p>}
                        </div>


                        <div className="mb-2">
                            <label>Model</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.model}
                                onChange={(e) => setNewCar({ ...newCar, model: e.target.value })}
                            />
                            {errors.model && <p className="text-red-500">{errors.model}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.make}
                                onChange={(e) => setNewCar({ ...newCar, make: e.target.value })}
                            />
                            {errors.make && <p className="text-red-500">{errors.make}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Insurer</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.insurer}
                                onChange={(e) => setNewCar({ ...newCar, insurer: e.target.value })}
                            />
                            {errors.insurer && <p className="text-red-500">{errors.insurer}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Insurance Expiry</label>
                            <input
                                type="date"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.insurer_expiry}
                                onChange={(e) => setNewCar({ ...newCar, insurer_expiry: e.target.value })}
                            />
                            {errors.insurer_expiry && <p className="text-red-500">{errors.insurer_expiry}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Year Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.year}
                                onChange={(e) => setNewCar({ ...newCar, year: e.target.value })}
                            />
                            {errors.year && <p className="text-red-500">{errors.year}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Status</label>
                            <select
                                
                                className="border rounded w-full px-2 py-1"
                                value={newCar.status}
                                onChange={(e) => setNewCar({...newCar, status: e.target.value})}
                            >
                                <option>AVAILABLE</option>
                                <option>ASSIGNED</option>
                                <option>UNDER MAINTENANCE</option>
                                <option>RESERVED</option>
                                <option>OUT OF SERVICE</option>
                                <option>RETIRED</option>
                                <option>WAITING ASSIGNMENT</option>
                                <option>SCHEDULED FOR DISPOSAL</option>
                                <option>PENDING INSPECTION</option>
                                <option>DECOMMISSIONED</option>
                            </select>

                            {errors.status && <p className="text-red-500">{errors.status}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Drive Link</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="border rounded w-full px-2 py-1"
                                value={newCar.drive_link}
                                onChange={(e) => setNewCar({ ...newCar, drive_link: e.target.value })}
                            />
                            {errors.drive_link && <p className="text-red-500">{errors.drive_link}</p>}
                        </div>

                      <div className="">
  <label>Driver Id</label>
  <input
    type="text"
    autoComplete="off"
    className="border rounded w-full px-2 py-1"
    value={selectedDriverId}
    readOnly
  />
  {errors.driver_id && <p className="text-red-500">{errors.driver_id}</p>}
</div>

<div className="mb-1">
  <label>Driver Name</label>
  <select
    name="driver_name"
    className="border rounded w-full px-2 py-1"
    value={selectedDriverName}
    onChange={(e) => {
      const selectedDriver = drivers.find(driver => driver.name === e.target.value);
      setSelectedDriverName(e.target.value);
      
      setSelectedDriverId(selectedDriver ? selectedDriver.id_no : ''); 
      setNewCar({ ...newCar,
         driver_id: selectedDriver ? selectedDriver.id_no : '',
         driver_name:e.target.value
        }); 

      console.log( 'drivername' ,selectedDriverName);
    }}
  >
    <option value="">Select the driver</option>
    <option value="N/A">N/A</option>
    {drivers.map((driver) => (
      <option key={driver.id_no} value={driver.name}>
        {driver.name}
      </option>
    ))}
  </select>
  {errors.driver_name && <p className="text-red-500">{errors.driver_name}</p>}
</div>


                    <div className="col-span-2 flex justify-end items-center space-x-1 mt-4">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white px-6 py-2 rounded mr-2"
                            onClick={handleAddCar}
                        >
                            Add
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-6 py-2 rounded "
                            onClick={() => setShowAddCarForm(false)}
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                    </div>
                    </div>

            )}

            {editingCar && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-auto">
                     <h2 className="text-xl font-bold mb-4">Edit Car</h2>
                    <div className=" grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="mb-2">
                     <label htmlFor="plateNumber">Plate Number</label>
                     <input
                      id="plateNumber"
                      type="text"
                      className="border rounded w-full px-2 py-1"
                      value={editingCar.plate_no}
                      onChange={(e) => {
                 const plate_noValue = e.target.value.toUpperCase();

                 if (plate_noValue.startsWith('K')) {
                 if (/^[A-Z0-9\s]*$/.test(plate_noValue) && plate_noValue.length <= 7) {
                    setEditingCar({ ...editingCar, plate_no: plate_noValue });

                    if (plate_noValue.length === 7) {
                        setErrors((prevErrors) => ({ ...prevErrors, plate_no: '' }));
                          }
                            }
                                } else {
                                 setErrors((prevErrors) => ({ ...prevErrors, plate_no: 'Plate number must start with "K"' }));
                              }
                              }}
                             />
                           {errors.plate_no && <p className="text-red-500">{errors.plate_no}</p>}
                        </div>

                        <div className="mb-1">
                            <label>Model</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.model}
                                onChange={(e) => setEditingCar({ ...editingCar, model: e.target.value })}
                            />
                            {errors.model && <p className="text-red-500">{errors.model}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.make}
                                onChange={(e) => setEditingCar({ ...editingCar, make: e.target.value })}
                            />
                            {errors.make && <p className="text-red-500">{errors.make}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Insurer</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.insurer}
                                onChange={(e) => setEditingCar({ ...editingCar, insurer: e.target.value })}
                            />
                            {errors.insurer && <p className="text-red-500">{errors.insurer}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Insurance Expiry</label>
                            <input
                                type="date"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.insurer_expiry}
                                onChange={(e) => setEditingCar({ ...editingCar, insurer_expiry: e.target.value })}
                            />
                            {errors.insurer_expiry && <p className="text-red-500">{errors.insurer_expiry}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Year Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.year}
                                onChange={(e) => setEditingCar({ ...editingCar, year: e.target.value })}
                            />
                            {errors.year && <p className="text-red-500">{errors.year}</p>}
                        </div>
                        <div>
                            <label>Status</label>
                            <select
                               className="border rounded w-full px-2 py-1"
                               value={editingCar.status}
                               onChange= {(e) => setEditingCar({ ...editingCar, status: e.target.value})}
                            >
                                <option>AVAILABLE</option>
                                <option>ASSIGNED</option>
                                <option>UNDER MAINTENANCE</option>
                                <option>RESERVED</option>
                                <option>OUT OF SERVICE</option>
                                <option>RETIRED</option>
                                <option>WAITING ASSIGNMENT</option>
                                <option>SCHEDULED FOR DISPOSAL</option>
                                <option>PENDING INSPECTION</option>
                                <option>DECOMMISSIONED</option> 
                            </select>
                        </div>
                        <div className="mb-1">
                            <label>Driver Name</label>
                            <select
                                name="driver_name"
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.driver_name}
                                onChange={handleEditingCarDriverNameChange}
                            >
                                <option>select the driver</option>
                                {drivers.map((driver) => (
                                        <option key={driver.id} value={driver.name}>
                                            {driver.name}
                                        </option>
                                    ))}


                            </select>
                            {errors.driver_name && <p className="text-red-500">{errors.driver_name}</p>}
                        </div>
                        <div className=''>
                          <label>Driver Id</label>
                          <input
                             type="text"
                             autoComplete="off"
                             className="border rounded w-full px-2 py-1"
                             value={editingCar.driver_id}
                             readOnly 
                             onChange={(e) => setEditingCar({ ...editingCar, driver_id: e.target.value })}
                          />
                        {errors.driver_id && <p className="text-red-500">{errors.driver_id}</p>}
                       </div>
                       <div className=''>
                          <label>Edit By</label>
                          <input
                             type="text"
                             autoComplete="off"
                             className="border rounded w-full px-2 py-1"
                             value={editingCar.edit_by}
                             readOnly 
                             onChange={(e) => setEditingCar({ ...editingCar, edit_by: e.target.value })}
                          />
                        {errors.edit_by && <p className="text-red-500">{errors.edit_by}</p>}
                       </div>
                       <div className=''>
                          <label>Edit Date</label>
                          <input
                             type="text"
                             autoComplete="off"
                             className="border rounded w-full px-2 py-1"
                             value={editingCar.edit_date}
                             readOnly 
                             onChange={(e) => setEditingCar({ ...editingCar, edit_date: e.target.value })}
                          />
                        {errors.edit_date && <p className="text-red-500">{errors.edit_date}</p>}
                       </div>
                       <div className=''>
                          <label>Drive link</label>
                          <input
                             type="text"
                             autoComplete="off"
                             className="border rounded w-full px-2 py-1"
                             value={editingCar.drive_link} 
                             onChange={(e) => setEditingCar({ ...editingCar, drive_link: e.target.value })}
                          />
                        {errors.drive_link && <p className="text-red-500">{errors.drive_link}</p>}
                       </div>
                        <div className='col-span-2 flex justify-end space-x-1 mt-4 items-center'>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white px-6 py-2 rounded mr-2"
                            onClick={handleUpdateCar}
                        >
                            Update
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-6 py-2 rounded"
                            onClick={() => setEditingCar(null)}
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                </div>
                </div>
            )}
        </div>
    );
};
export default MyCarsApp;
