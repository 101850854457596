import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import Select from 'react-select';

const Transaction = () => {
  const { rootUrl } = require('../../config/config');
  const { user } = useAuth();
  
  const [drivers, setDrivers] = useState([]);
  const [cars, setCars] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedBalance, setSelectedBalance] = useState('');
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  
  const [newTrans, setNewTrans] = useState({
    car_trans_type: '',
    date: '',
    amount: '',
    notes: '',
    car: '',
    bal:'',
    input_by: '',
    your_ref:'',

  });



  useEffect(() => {
    if (user && user.username) {
      setNewTrans((prevTrans) => ({
        ...prevTrans,
        input_by: user.username,
      }));
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken') || 'default-token';
      try {
        const [carResponse, driverResponse, typeResponse] = await Promise.all([
          axios.get(`${rootUrl}/cars`, {
            headers: { 'Authorization': `Bearer ${token}` },
          }),
          axios.get(`${rootUrl}/drivers`, {
            headers: { 'Authorization': `Bearer ${token}` },
          }),
          axios.get(`${rootUrl}/payments/types/car`, {
            headers: { 'Authorization': `Bearer ${token}` },
          })
        ]);

        setDrivers(driverResponse.data);
        setCars(carResponse.data)
        setTypes(typeResponse.data.map(item => ({
          id: item.id,
          type: item.type,
          short_desc: item.short_desc,
          long_desc: item.long_desc,
        })));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleAddTransaction = async () => {
    let newErrors = {};

    if (!newTrans.car_trans_type) newErrors.car_trans_type = 'Please fill in transaction type';
    if (!newTrans.car) newErrors.car = 'Please fill in car';
    if (!newTrans.date) newErrors.date = 'Please fill in date';
    if (!newTrans.notes) newErrors.notes = 'Please fill in the notes';
    if (!newTrans.amount) newErrors.amount = 'Please fill in amount';
    if (!newTrans.your_ref) newErrors.your_ref = 'Please fill in ref code';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSaving(true)

    try {
      const transData = { ...newTrans, amount: parseFloat(newTrans.amount) };
      const response = await axios.post(`${rootUrl}/cars/${newTrans.car}/trans`, transData);

      if (response.status === 200) {
        setSuccessMessage("Transaction added successfully!");
        setTimeout(() => setSuccessMessage(''), 6000);
        setNewTrans({
          car_trans_type: '',
          date: '',
          amount: '',
          notes: '',
          car: '',
          bal:'',
          input_by: user.username,
          your_ref:''
        });
        setSelectedDriver('');
        setSelectedBalance('');
      }

    } catch (error) {
      console.error("Error adding transaction: ", error);
      if (error.response && error.response.data) {
        setErrors({ api: error.response.data.error });
      }
    } finally{
      setIsSaving(false)
    }
  };

  const handleCarPlateChange = (e) => {
    const selectedPlate = e.target.value;
    setNewTrans((prevTrans) => ({ ...prevTrans, car: selectedPlate }));

    const selectedCar = cars.find(car => car.plate_no === selectedPlate);
    setSelectedDriver(selectedCar ? selectedCar.driver_name : '');
    setSelectedBalance(selectedCar ? selectedCar.bal : '');
  };

  const isSaveDisabled = !newTrans.car || !newTrans.notes || !newTrans.amount;

  return (
    <div className="bg-white rounded-lg p-4 mb-4">
      <h1 className="text-2xl font-bold mb-6 text-center">Transactions</h1>

      {successMessage && (
        <div className="bg-green-300 text-center text-green-800 p-2 rounded mb-4">
          {successMessage}
        </div>
      )}

      <div className="flex flex-col-2 space-y-4 mb-4 items-center">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:flex-row md:space-x-4 space-y-4 mb-4 items-center">
      <div className="flex items-center space-x-4">
    <label className="font-semibold">Trans Date:</label>
    <input
      type="date"
      value={newTrans.date}
      onChange={(e) => {
        // Clear the error for 'date' when the user updates the input
        setNewTrans({ ...newTrans, date: e.target.value });
        if (errors.date) {
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.date; // Remove the 'date' error when the input changes
            return newErrors;
          });
        }
      }}
      className="border rounded px-2 py-1 w-full"
    />
    {errors.date && <p className="text-red-500">{errors.date}</p>}
    </div>
    <div className="flex items-center space-x-4">
  <label className="font-semibold">Trans-Type:</label>
  <Select
    value={
      newTrans.car_trans_type
        ? { value: newTrans.car_trans_type, label: types.find((type) => type.type === newTrans.car_trans_type)?.long_desc || '' }
        : null
    }
    onChange={(selectedOption) =>
      setNewTrans({
        ...newTrans,
        car_trans_type: selectedOption ? selectedOption.value : '',
      })
    }
    options={types.map((type) => ({
      value: type.type, // Option value (e.g., 'type_1', 'type_2')
      label: type.long_desc, // Option label (e.g., 'Transaction Type 1', 'Transaction Type 2')
    }))}
    placeholder="Choose type"
    className="w-full"
    classNamePrefix="custom-select"
  />
  {errors.car_trans_type && <p className="text-red-500">{errors.car_trans_type}</p>}
</div>


    <div className="flex items-center space-x-4">
  <label className="font-semibold">Car Plate:</label>
  <Select
    value={
      newTrans.car
        ? { value: newTrans.car, label: newTrans.car } // Ensure correct format for the value
        : null
    }
    onChange={(selectedOption) =>
      handleCarPlateChange({
        target: { value: selectedOption.value }, // Update your state correctly
      })
    }
    options={cars.map((car) => ({
      value: car.plate_no,
      label: car.plate_no,
    }))}
    placeholder="Select car plate"
    className="w-full"
    classNamePrefix="custom-select"
  />
  {errors.car && <p className="text-red-500">{errors.car}</p>}
</div>

  <div className="flex items-center space-x-4">
    <label className="font-semibold">Reference</label>
    <input
      type="text"
      value={newTrans.your_ref}
      onChange={(e) => {
        const uppercaseValue = e.target.value.toUpperCase();
       
        setNewTrans({ ...newTrans, your_ref: uppercaseValue });
        if (errors.your_ref) {
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.your_ref;
            return newErrors;
          });
        }
      }}
      className="border rounded px-2 py-1 w-full"
      
    />
    {errors.reference && <p className="text-red-500">{errors.reference}</p>}
  </div>
  <div className="flex items-center space-x-4">

<label className="font-semibold">Driver:</label>
<input
  type="text"
  value={selectedDriver}
  className="border rounded px-2 py-1 w-full cursor-not-allowed"
  disabled
/>
</div>
<div className="flex items-center space-x-4"> 
    <label className="font-semibold">Total Balance</label>
    <input
      type="text" // Use "text" for formatted display instead of "number"
      value={
        selectedBalance
          ? new Intl.NumberFormat('en-KE', {
              style: 'currency',
              currency: 'KES',
            }).format(selectedBalance)
          : 'KES 0.00'
      } 
      className="border rounded px-2 py-1 w-full cursor-not-allowed text-right"
      disabled
    />
</div>
</div>

      </div>
        <div className="flex flex-col space-y-4">
          <div>
            <label className="font-semibold">Notes:</label>
            <textarea
              value={newTrans.notes}
              onChange={(e) => setNewTrans({ ...newTrans, notes: e.target.value })}
              className="flex border rounded px-2 py-1 w-full h-24 resize-none bg-yellow-100"
              placeholder="Enter transaction notes"
            />
          </div>

          <div className="flex justify-end">
            <label className="font-semibold p-2">Amount:</label>
            <input
              type="number"
              value={newTrans.amount}
              onChange={(e) => setNewTrans({ ...newTrans, amount: e.target.value })}
              className="border rounded px-2 py-1 w-32 text-right bg-yellow-100"
              placeholder="Enter amount"
              min="0"
            />
          </div>

          <div className="flex justify-end space-x-4">
          <button
              className={`bg-blue-500 text-white font-semibold py-2 px-4 rounded w-20 ${
                 isSaveDisabled || isSaving ? 'opacity-50 cursor-not-allowed' : ''
               }`}
              onClick={handleAddTransaction}
              disabled={isSaveDisabled || isSaving} // Ensure the button is disabled during saving
              >
                {isSaving ? 'Saving...' : 'Save'}
             </button>


            <button
              className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded w-20"
              onClick={() => {
                setNewTrans({
                  car_trans_type: '',
                  date: '',
                  amount: '',
                  notes: '',
                  car: '',
                  bal:'',
                  input_by: user.username,
                  reference:''
                });
                setSelectedDriver('');
                setSelectedBalance('');
              }}
            >
              Reset
            </button>
          </div>
      </div>
    </div>
  );
};

export default Transaction;
