import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Commons from './../utils/Commons';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const { rootUrl } = require('../../config/config');
const common = new Commons();

function EndOfWeek({ setSuccessMessage, setFailMessage }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [dateFrom, setDateFrom] = useState('');
    const [isPosting, setIsPosting] = useState(false);

    const getCurrentCalendarWeek = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const week = Math.ceil(
            ((currentDate - new Date(year, 0, 1)) / 86400000 + new Date(year, 0, 1).getDay() + 1) / 7
        );
        return `${year}_${String(week).padStart(2, '0')}`; // Format as "YYYY_WW"
    };

    const [yearMonth, setYearMonth] = useState(getCurrentCalendarWeek());

    useEffect(() => {
        const fetchStoredCalendarWeek = async () => {
            const week = await common.getStoredCalendarWeek();
            setYearMonth(week);
        };

        const savedDateFrom = localStorage.getItem('date_from');
        if (savedDateFrom) {
            setDateFrom(savedDateFrom);
            console.log('Retrieved date_from from localStorage:', savedDateFrom); // Debug log
        }

        fetchStoredCalendarWeek();
        console.log('Current user:', user);
    }, [user]);

    const handleEndWeekClick = async () => {
        const currentWeek = getCurrentCalendarWeek();

        if (currentWeek === yearMonth) {
            const today = new Date().toISOString().split('T')[0];
            setDateFrom(today);
            localStorage.setItem('date_from', today);

            console.log('Updated dateFrom:', today); // Debug log
            console.log('Saved in localStorage:', localStorage.getItem('date_from')); // Debug log

            setFailMessage("You cannot end the week because the calendar week has not advanced ⛔.");
            setTimeout(() => setFailMessage(''), 6000); // Clear message after 6 seconds
            return; // Exit early
        }

        try {
            setIsPosting(true); // Disable button
            const response = await axios.post(
                `${rootUrl}/system/end_week`,
                { input_by: user.username },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            if (response.data.newWeek) setYearMonth(response.data.newWeek);

            setSuccessMessage("End week added successfully ✅!");
            setTimeout(() => setSuccessMessage(''), 6000); // Clear message after 6 seconds
        } catch (error) {
            setFailMessage("End Week failed to add!");
            setTimeout(() => setFailMessage(''), 6000); // Clear message after 6 seconds
            console.error('End week error:', error);
        } finally {
            setIsPosting(false); // Re-enable button
        }
    };

    return (
        <div className="flex flex-col items-start space-y-6">
            {/* Calendar Week Display */}
            <div className="w-full flex flex-col items-start">
                <label className="text-lg font-semibold text-gray-700 mb-1">Calendar Week:</label>
                <p className="text-lg border border-gray-300 bg-yellow-100 rounded-md px-4 py-2">
                    {getCurrentCalendarWeek()}
                </p>
            </div>

            {/* System Week Display */}
            <div className="w-full flex flex-col items-start">
                <label className="text-lg font-semibold text-gray-700 mb-1">System Week:</label>
                <p className="text-lg border border-gray-300 bg-yellow-100 rounded-md px-4 py-2">
                    {yearMonth}
                </p>
            </div>

            

            {/* Button to End Week */}
            <div className="w-full flex justify-start">
                <button
                    className={`bg-blue-600 text-white font-semibold px-6 py-2 rounded-md hover:bg-blue-700 transition ${isPosting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleEndWeekClick}
                    disabled={isPosting}
                >
                    {isPosting ? 'Processing...' : 'End Week'}
                </button>
            </div>
        </div>
    );
}

export default EndOfWeek;
