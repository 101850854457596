import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';




const MyDriversApp = () => {
    const { rootUrl } = require('../../config/config');
    const [drivers, setDrivers] = useState([]);
    const { isAuthenticated } = useAuth();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('name');
    const { user } = useAuth();
    const { theme } = useTheme(); 
    const [showAddDriverForm, setShowAddDriverForm] = useState(false);
    const[successMessage, setSuccessMessage] = useState('');
    const [newDriver, setNewDriver] = useState({
        name: '',
        phone: '',
        id_no: '',
        license_no: '',
        license_expiry: '',
        referred_by:'',
        status:'',
        drive_link: '',
        input_by:'',
        input_date: '',
        edit_date: '',
        edit_by: ''
        
       
    });
    const [errors, setErrors] = useState({
        id:'',
        name: '',
        phone: '',
        id_no: '',
        license_no: '',
        license_expiry: '',  
        referred_by:'',
        status:'',
        drive_link: '',
        input_by:'',
        input_date: '',
        edit_date: '',
        edit_by: ''
        
        
    });
    const [editingDriver, setEditingDriver] = useState(null);
    const [viewingDriver, setViewingDriver] = useState(null);
    useEffect(() => {
        console.log('User:', user); // Check user information
        if (user && user.username) {
            const currentDate = new Date().toISOString(); // Get current date in ISO format
            setNewDriver((prevDriver) => ({
                ...prevDriver,
                input_by: user.username,
                input_date: currentDate,
                edit_by: user.username,
                edit_date: currentDate,
            }));
        }
    }, [user]);

    useEffect(() => {
        const fetchDrivers = async () => {
            const token = localStorage.getItem('authToken')|| 'default-token';

            try {
                const response = await axios.get(`${rootUrl}/drivers`,{
                    headers:{
                        'Authorization': `Bearer ${token}`

                    }
                });
                console.log('this is the token' , token)
                const data = response.data;
    
                const mappedData = data.map(item => {
                    
                    
         return {
                id: item.id || '',
                name: item.name || 'No name provided',
                phone: item.phone || 'No phone provided',
                id_no: item.id_no || 'Unknown',
                license_no: item.license_no || 'Unknown',
                license_expiry: item.license_expiry ? new Date(item.license_expiry).toISOString().split('T')[0] : 'no licence exp',
                referred_by: item.referred_by || 'Unknown',
                status: item.status || 'No status',
                bal: item.bal || '  0.00',
                drive_link: item.drive_link || 'provide drive link',
                input_by: item.input_by || 'Unknown',
                input_date: item.input_date ? new Date(item.input_date).toISOString().split('T')[0] : 'Unknown',
                edit_date: item.edit_date ? new Date(item.edit_date).toISOString().split('T')[0] : 'Unknown',
                edit_by: item.edit_by || 'Unknown'

                    };
                });
    
                setDrivers(mappedData);
                console.log('Mapped Data:', mappedData);
            } catch (error) {
                console.error("Error fetching messages: ", error);
            }
        };
    
        fetchDrivers();
    }, []);
    

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };
    

    
    const handleAddDriver = async () => {
        let newErrors = {};
    
        // Validation checks
        if (!newDriver.name) newErrors.name = 'Driver name is required';
        if (!/^\d{10}$/.test(newDriver.phone)) newErrors.phone = 'Phone must be exactly 10 digits';
        if (!newDriver.id_no) newErrors.id_no = 'Identification number is required';
        if (!newDriver.status) newErrors.status = 'Status is required';
        if (!newDriver.drive_link) newErrors.drive_link = 'Drive link is required';
        if (!newDriver.license_expiry) newErrors.license_expiry = 'License expiry is required';
        if (!newDriver.input_by) newErrors.input_by = 'You must specify who inputted the data';
        if (!newDriver.input_date) newErrors.input_date = 'Input date is required';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        try {
            const timestamp = new Date().toISOString();
            const driverData = {
                id: newDriver.id,
                name: newDriver.name,
                phone: newDriver.phone,
                id_no: newDriver.id_no,
                license_no: newDriver.license_no,
                status: newDriver.status,
                drive_link: newDriver.drive_link,
                referred_by: newDriver.referred_by,
                input_by: user.username,
                input_date: timestamp,
                license_expiry: newDriver.license_expiry || null,
            };
    
            // Make the API call
            const response = await axios.post(`${rootUrl}/drivers`, driverData);
    
            const addedDriver = {
                ...driverData,
                id: response.data.id,
                edit_date: timestamp,
            };
    
            // Add to drivers list
            setDrivers(prevDrivers => [...prevDrivers, addedDriver]);
    
            // Close the form and reset fields
            setShowAddDriverForm(false);
            setNewDriver({
                id: '',
                name: '',
                phone: '',
                id_no: '',
                license_no: '',
                license_expiry: '',
                referred_by: '',
                status: '',
                drive_link: '',
                input_by: user.username,
                input_date: timestamp,
                edit_by: '',
                edit_date: timestamp
            });
            
            setErrors({});
            setSuccessMessage('Driver added successfully!');
            setTimeout(() => setSuccessMessage(''), 6000);
    
        } catch (error) {
            console.error("Error adding driver: ", error);
            if (error.response && error.response.data) {
                setErrors({ api: error.response.data.error });
            }
        }
    };
    
    
    

  
    
    
    const handleEditDriver = (id) => {
        const driverToEdit = drivers.find(driver => driver.id === id);
     
        
        const formattedDate = driverToEdit.license_expiry ? new Date(driverToEdit.license_expiry).toISOString().slice(0, 10): '';// datetime local fomart
        setEditingDriver({ ...driverToEdit, license_expiry: formattedDate });
    
    };

    
    const handleUpdateDriver = async () => {
        if (!editingDriver) return;
    
        let newErrors = {};
    
        
        if (!editingDriver.name) newErrors.name = 'Driver name is required';
        if (!/^\d{10}$/.test(editingDriver.phone)) newErrors.phone = 'Phone must be exactly 10 digits';
        if (!editingDriver.id_no) newErrors.id_no = 'ID Number is required';
        if (!editingDriver.input_by) newErrors.input_by = 'Input By is required';
        if (!editingDriver.input_date) newErrors.input_date = 'Input Date is required';
        if (!editingDriver.status) newErrors.status = 'Status is required';
        if (!editingDriver.drive_link) newErrors.drive_link = 'drive link is required';
    
        // Set edit date to current date
        const currentEditDate = new Date().toISOString().split('T')[0]; 
        if (!editingDriver.edit_date) editingDriver.edit_date = currentEditDate;
    
        // Get the logged-in user
        const loggedInUser = user?.username; 
        if (!editingDriver.edit_by) editingDriver.edit_by = loggedInUser;
    
        
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        
        const { id, name, phone, id_no, license_no, license_expiry, referred_by, drive_link, status, input_by, input_date, edit_by, edit_date } = editingDriver;
    
        
        const formattedLicenseExpiry = license_expiry ? new Date(license_expiry).toISOString().split('T')[0] : null;
    
        if (!formattedLicenseExpiry || isNaN(Date.parse(formattedLicenseExpiry))) {
            setErrors((prevErrors) => ({ ...prevErrors, license_expiry: 'Invalid license expiry date' }));
            return;
        }
    
        try {
            
            await axios.put(`${rootUrl}/drivers/${id}`, { 
                name, 
                phone, 
                id_no, 
                license_no, 
                license_expiry: formattedLicenseExpiry, 
                referred_by, 
                status,
                drive_link, 
                input_by, 
                input_date, 
                edit_by:user.username
            });
    
            
            const updatedDrivers = drivers.map(driver =>
                driver.id === id 
                ? { 
                    ...driver, 
                    name, 
                    phone, 
                    id_no, 
                    license_no, 
                    license_expiry: formattedLicenseExpiry, 
                    referred_by, 
                    status, 
                    drive_link,
                    input_by, 
                    input_date, 
                    edit_by, 
                    edit_date 
                } 
                : driver
            );
            setDrivers(updatedDrivers);
            setEditingDriver(null); // Reset editing state
    
            console.log('Updated drivers:', updatedDrivers);
        } catch (error) {
            console.error("Error updating driver: ", error);
        }
    };
    
     
    
    
    const handleDeleteDriver = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this driver?');
        if (!isConfirmed) {
            return;
        }
        try {
            await axios.delete(`${rootUrl}/drivers/${id}`);
            const updatedDrivers = drivers.filter(driver => driver.id !== id);
            setDrivers(updatedDrivers);
        } catch (error) {
            console.error("Error deleting driver: ", error);
        }
    };

    const handleViewDriver = (id) => {
        const driverToView = drivers.find(driver => driver.id === id);
        setViewingDriver(driverToView);
    };

    const filteredDrivers = drivers.filter(driver => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        switch (searchBy) {
            case 'name':
                return driver.name.toLowerCase().includes(lowerCaseSearchTerm);
            case 'phone':
                return driver.phone.toLowerCase().includes(lowerCaseSearchTerm);
            case 'id_no':
                return driver.id_no.toLowerCase().includes(lowerCaseSearchTerm);
            case 'license_no':
                return driver.license_no.toLowerCase().includes(lowerCaseSearchTerm);
            case 'license expiry':
                return new Date(driver.timestamp).toLocaleString().toLowerCase().includes(lowerCaseSearchTerm);
            case 'referred_by':
                return driver.referred_by.toLowerCase().includes(lowerCaseSearchTerm);
            case 'status':
                return driver.status.toLowerCase().includes(lowerCaseSearchTerm);
            case 'drive_link':
                return driver.drive_link.toLowerCase().includes(lowerCaseSearchTerm);
            default:
                return false;
        }
    });

    

    const totalFilteredAmount = filteredDrivers.reduce(
        (total, driver) => total + Number(driver.bal || 0),
        0
    );

    const driverCount = filteredDrivers.length;

    return (
        <div className={`min-h-screen ${theme}`}>
            <div className="container mx-auto p-4">
                <h2 className="text-center text-xl md:text-2xl font-bold mb-4">Drivers Onboard</h2>
                
                {successMessage && (
                    <div className="bg-green-300 text-center text-green-800 p-2 rounded mb-4">
                        {successMessage}
                    </div>
                )}
    
                {/* Search and Filter Section */}
                <div className="flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0 mb-4">
                    <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0">
                        <label className="md:mr-2">Search By:</label>
                        <select
                            className="border rounded px-2 py-1 text-xs md:text-sm w-full md:w-auto"
                            value={searchBy}
                            onChange={handleSearchByChange}
                        >
                            <option value="name">Driver Name</option>
                            <option value="phone">Phone</option>
                            <option value="id_no">ID Number</option>
                            <option value="license_no">License Number</option>
                            <option value="license_expiry">License Expiry</option>
                            <option value="referred_by">Referred By</option>
                            <option value="status">Status</option>
                            <option value="drive_link">Drive Link</option>
                        </select>
                    </div>
                    
                    <input
                        type="text"
                        placeholder={`Search by ${searchBy}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="border rounded px-2 py-1 w-full md:w-1/3 text-xs md:text-sm"
                    />
                    
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-1 rounded text-xs md:text-sm w-full md:w-auto"
                        onClick={() => setShowAddDriverForm(true)}
                    >
                        New Driver
                    </button>
                </div>

                <div className="flex justify-between bg-slate-300 items-center mb-4">
                <p>Total Drivers: {driverCount}</p>
                <p className="text-right">Total Amount: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(totalFilteredAmount)}</p>
            </div>
    
                {/* Table Section */}
                <div className="overflow-x-auto">
                    <table className="min-w-full border-collapse">
                        <thead>
                            <tr className="bg-slate-800 text-white text-xs md:text-sm">
                                <th className="border px-2 py-1 text-left">Driver Name</th>
                                <th className="border px-2 py-1 text-left">Phone</th>
                                <th className="border px-2 py-1 text-left hidden lg:table-cell">ID No</th>
                                <th className="border px-2 py-1 text-left hidden md:table-cell">License No</th>
                                <th className="border px-2 py-1 text-left hidden lg:table-cell">License Expiry</th>
                                <th className="border px-2 py-1 text-left hidden xl:table-cell">Referred By</th>
                                <th className="border px-2 py-1 text-right hidden xl:table-cell">Balance</th>
                                <th className="border px-2 py-1 text-left">Status</th>
                                <th className="border px-2 py-1 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDrivers.map((driver, index) => (
                                <tr key={driver.id} className={`${index % 2 === 0 ? "bg-white" : "bg-slate-200"} text-xs md:text-sm`}>
                                    <td className="border px-2 py-1">{driver.name}</td>
                                    <td className="border px-2 py-1">{driver.phone}</td>
                                    <td className="border px-2 py-1 hidden lg:table-cell">{driver.id_no}</td>
                                    <td className="border px-2 py-1 hidden md:table-cell">{driver.license_no}</td>
                                    <td className="border px-2 py-1 hidden lg:table-cell">
                                        {driver.license_expiry ? driver.license_expiry.split('T')[0] : 'No license expiry'}
                                    </td>
                                    <td className="border px-2 py-1 hidden xl:table-cell">{driver.referred_by}</td>
                                    <td className="border text-right px-2 py-1 hidden xl:table-cell">
                                        {driver.bal}
                                    </td>
                                    <td className="border px-2 py-1">{driver.status}</td>
                                    <td className="border px-2 py-1 flex justify-center space-x-1">
                                        <button
                                            className="bg-slate-400 text-white p-1 rounded"
                                            onClick={() => handleEditDriver(driver.id)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button
                                            className="bg-green-700 text-white p-1 rounded"
                                            onClick={() => handleViewDriver(driver.id)}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </button>
                                        <button
                                            className="bg-red-700 text-white p-1 rounded"
                                            onClick={() => handleDeleteDriver(driver.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                </div>
            </div>
    
            {showAddDriverForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-auto">
                        <h2 className="text-xl font-bold mb-4">New Driver</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-1">
                            <label>Driver Name</label>
                            <input
                                autoComplete='off'
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.name}
                                onChange={(e) => setNewDriver({ ...newDriver, name: e.target.value.toUpperCase() })}
                            />
                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Phone</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.phone}
                                onChange={(e) => {
                                    const phoneValue = e.target.value;
                                    if (/^\d{0,10}$/.test(phoneValue)) {
                                        setNewDriver({ ...newDriver, phone: phoneValue });

                                        if(phoneValue.length === 10) {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
                                    } else {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: 'Phone must be exactly 10 digits' }));
                                    }
                                  }
                                }}
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mb-1">
                            <label>ID number</label>
                            <input
                            
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.id_no}
                                onChange={(e) => setNewDriver({ ...newDriver , id_no: e.target.value })}
                            />
                            {errors.id_no && <p className="text-red-500">{errors.id_no}</p>}
                        </div>
                        <div className="mb-1">
                            <label>License No</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.license_no}
                                onChange={(e) => setNewDriver({ ...newDriver, license_no: e.target.value })}
                            />
                            {errors.license_no && <p className="text-red-500">{errors.license_no}</p>}
                        </div>
                        <div className="mb-1">
                            <label>License Expiry</label>
                            <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="date"
                            autoComplete='off'
                            value={newDriver.license_expiry}
                            onChange={e => setNewDriver({ ...newDriver, license_expiry: e.target.value })}
                        />

                            {errors.license_expiry && <p className="text-red-500">{errors.license_expiry}</p>}
                        </div>
                        
                        <div className="mb-1">
                            <label>Reffered By:</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.referred_by}
                                onChange={(e) => setNewDriver({ ...newDriver, referred_by: e.target.value })}
                            />
                            {errors.referred_by && <p className="text-red-500">{errors.referred_by}</p>}
                        </div>

                        <div className="mb-1">
                            <label>Status</label>
                            <select
                                type="text"
                                autoComplete='off'
                                className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.status}
                                onChange={(e) => setNewDriver({ ...newDriver, status: e.target.value })}
                             >
                                <option value="">select</option>
                                <option>AVAILABLE</option>
                                <option>OFF DUTY</option>
                                <option>ON BREAK</option>
                                <option>ON LEAVE</option>
                                <option>UNDER TRAINING</option>
                                <option>ASSIGNED</option>
                                <option>UNAVAILABLE</option>
                                <option>SUSPENDED</option>
                                <option>RETIRED</option>
                                <option>AWAITING ASSIGNMENT</option>
                             </select>
                            {errors.status && <p className="text-red-500">{errors.status}</p>}
                        </div>
                       

                        {/*additional field*/}

                        <div className="mb-1">
                            <label>Driver Link</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.drive_link}
                                onChange={(e) => setNewDriver({ ...newDriver, drive_link: e.target.value })}
                            />
                            {errors.drive_link && <p className="text-red-500">{errors.drive_link}</p>}
                        </div>

                        
                        
                        
                       <div className='m-6'>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 px-6 py-2 text-white  rounded mr-3 "
                            onClick={handleAddDriver}
                        >
                            Add
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 px-6 py-2 text-white  rounded "
                            onClick={() => setShowAddDriverForm(false)}
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            )}

{editingDriver && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 rounded shadow-md w-auto">
            <h2 className="text-xl font-bold mb-4">Edit Driver</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label>Driver Name</label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={editingDriver.name}
                        onChange={(e) => setEditingDriver({ ...editingDriver, name: e.target.value })}
                    />
                    {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>

                <div className="mb-2">
                    <label>Phone</label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={editingDriver.phone}
                        onChange={(e) => {
                            const phoneValue = e.target.value;
                            if (/^\d{0,10}$/.test(phoneValue)) {
                                setEditingDriver({ ...editingDriver, phone: phoneValue });
                            }
                            setErrors((prevErrors) => ({
                                ...prevErrors,
                                phone: phoneValue.length === 10 ? '' : 'Phone must be exactly 10 digits',
                            }));
                        }}
                    />
                    {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                </div>

                <div className="mb-2">
                    <label>ID number</label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={editingDriver.id_no}
                        onChange={(e) => setEditingDriver({ ...editingDriver, id_no: e.target.value })}
                    />
                    {errors.id_no && <p className="text-red-500">{errors.id_no}</p>}
                </div>

                <div className="mb-2">
                    <label>License No</label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={editingDriver.license_no}
                        onChange={(e) => setEditingDriver({ ...editingDriver, license_no: e.target.value })}
                    />
                    {errors.license_no && <p className="text-red-500">{errors.license_no}</p>}
                </div>

                <div className="mb-2">
                    <label>License Expiry</label>
                    <input
                        type="date"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={editingDriver.license_expiry}
                        onChange={(e) => setEditingDriver({ ...editingDriver, license_expiry: e.target.value })}
                    />
                    {errors.license_expiry && <p className="text-red-500">{errors.license_expiry}</p>}
                </div>

                <div className="mb-2">
                    <label>Referred By:</label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={editingDriver.referred_by}
                        onChange={(e) => setEditingDriver({ ...editingDriver, referred_by: e.target.value })}
                    />
                    {errors.referred_by && <p className="text-red-500">{errors.referred_by}</p>}
                </div>

                <div className="mb-2">
                    <label>Status</label>
                    <select
                        className="border rounded w-full py-2 px-3 text-gray-700"
                        value={editingDriver.status}
                        onChange={(e) => setEditingDriver({ ...editingDriver, status: e.target.value })}
                    >
                        <option value="">Select Status</option>
                        <option>AVAILABLE</option>
                        <option>OFF DUTY</option>
                        <option>ON BREAK</option>
                        <option>ON LEAVE</option>
                        <option>UNDER TRAINING</option>
                        <option>ASSIGNED</option>
                        <option>UNAVAILABLE</option>
                        <option>SUSPENDED</option>
                        <option>RETIRED</option>
                        <option>AWAITING ASSIGNMENT</option>
                    </select>
                    {errors.status && <p className="text-red-500">{errors.status}</p>}
                </div>
                

              
                <div className='m-6'>

                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white px-6 py-2 rounded mr-3"
                    onClick={handleUpdateDriver}
                >
                    Update
                </button>
                <button
                    className="bg-gray-500 hover:bg-gray-700 text-white px-6 py-2 rounded"
                    onClick={() => setEditingDriver(null)}
                >
                    Cancel
                </button>
                </div>
            </div>
        </div>
    </div>
)}

            {viewingDriver && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-96">
                        <h2 className="text-xl font-bold mb-4  text-slate-600 text-center">View Driver</h2>
                        <div className="mb-2">
                            <label className='font-bold' >Driver Name</label>
                            <p >{viewingDriver.name}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold' >Phone</label>
                            <p >{viewingDriver.phone}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>ID Number</label>
                            <p>{viewingDriver.id_no}</p>
                        </div>
                        <div className="mb-2">
                            <label  className='font-bold'>license Number</label>
                            <p>{viewingDriver.license_no}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>license Expiry</label>
                            <p>{new Date(viewingDriver.timestamp).toLocaleString()}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Reffered By:</label>
                            <p>{viewingDriver.referred_by}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Status </label>
                            <p>{viewingDriver.status}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Drive Link</label>
                            <p>{viewingDriver.drive_link}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Input by </label>
                            <p>{viewingDriver.input_by}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>input date </label>
                            <p>{viewingDriver.input_date}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Edit Date</label>
                            <p>{viewingDriver.edit_date}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Edited By </label>
                            <p>{viewingDriver.edit_by}</p>
                        </div>
                        
                      
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                            onClick={() => setViewingDriver(null)}
                        >
                            Close
                        </button>
                    </div>
                </div> 
            )}
        </div>
    );
};

export default MyDriversApp;
