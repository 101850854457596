import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
    const [user, setUser] = useState(null); // State to store user information

    // Function to check if the token is expired
    const isTokenExpired = (token) => {
        try {
            const { exp } = JSON.parse(atob(token.split('.')[1]));
            const expired = Date.now() >= exp * 1000 - 300000; // 5 minutes before expiry
            return expired;
        } catch (error) {
            console.error('Error checking token expiration:', error);
            return true;
        }
    };

    // Login function
    // Login function
const login = (accessToken, refreshToken) => {
    localStorage.setItem('authToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setAuthToken(accessToken);
    setIsAuthenticated(true);

    // Decode token to get user information 
    const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
    const username = decodedToken.username || "Guest"; // Handle missing username case
    setUser({ username });

    console.log('Login: User information stored', { username });
};

// Effect to check authentication state on mount
useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
        if (isTokenExpired(token)) {
            refreshToken().catch(logout);
        } else {
            setIsAuthenticated(true);
            setAuthToken(token);
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const username = decodedToken.username || "Guest"; // Fallback
            setUser({ username });
            console.log('User information restored:', { username });
        }
    }
}, []);

    
    


    // Logout function
    const logout = () => {
        console.log('Logout function called');
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        setAuthToken(null);
        setIsAuthenticated(false);
        setUser(null); // Clear user information
        console.log('Logout: Tokens removed and user cleared');
    };

    // Function to refresh token
    const refreshToken = async () => {
        try {
            const storedRefreshToken = localStorage.getItem('refreshToken');
            if (!storedRefreshToken) {
                console.error('No refresh token found');
                throw new Error('No refresh token found');
            }

            console.log('Sending refresh token:', storedRefreshToken);

            // Send the refresh token in the request body
            const response = await axios.post('/token', { token: storedRefreshToken });

            console.log('Response from server:', response);

            if (response.status === 200) {
                const { accessToken } = response.data;
                localStorage.setItem('authToken', accessToken);
                setAuthToken(accessToken);
                setIsAuthenticated(true);

                // Decode token to get user information
                const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
                const userDetails = { username: decodedToken.username, userId: decodedToken.userId };
                setUser(userDetails);

                console.log('refreshToken: User information restored after refresh', userDetails);
            } else {
                throw new Error('Token refresh failed');
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            logout(); // Log out if token refresh fails
        }
    };

    // Initial token check on component mount
    useEffect(() => {
        console.log('useEffect for initial token check called');
        const token = localStorage.getItem('authToken');
        if (token) {
            if (isTokenExpired(token)) {
                console.log('useEffect: Token is expired, refreshing...');
                refreshToken().catch(logout);
            } else {
                setIsAuthenticated(true);
                setAuthToken(token);
                console.log('useEffect: Token is valid, user authenticated');
                // Decode token to get user information
                const decodedToken = JSON.parse(atob(token.split('.')[1]));
                setUser({ username: decodedToken.username });  // Only restore username
                
                console.log('useEffect: User information restored', { username: decodedToken.username });
            }
        }
    }, []);
    

    // Interval for token refresh
    useEffect(() => {
        console.log('Setting up interval for token refresh');
        const interval = setInterval(() => {
            const token = localStorage.getItem('authToken');
            if (token) {
                if (isTokenExpired(token)) {
                    console.log('Interval: Token is expired, refreshing...');
                    refreshToken().catch(logout);
                }
            }
        }, 10 * 60 * 1000); // Check every 10 minutes

        return () => clearInterval(interval);
    }, []);

    // Logging auth state changes
    useEffect(() => {
        console.log('AuthState: isAuthenticated changed to', isAuthenticated);
    }, [isAuthenticated]);

    // Axios request interceptor
    axios.interceptors.request.use(
        (config) => {
            console.log('Request Interceptor called');
            const token = localStorage.getItem('authToken');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
                console.log('Request Interceptor: Added Authorization header', token);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Axios response interceptor
    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            console.log('Response Interceptor called');
            const originalRequest = error.config;
            if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    await refreshToken();
                    const token = localStorage.getItem('authToken');
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    console.log('Response Interceptor: Retrying request with new token', token);
                    return axios(originalRequest);
                } catch (refreshError) {
                    console.error('Error retrying request after token refresh failure:', refreshError);
                    logout(); // Logout if refresh token fails
                }
            }
            return Promise.reject(error);
        }
    );

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
