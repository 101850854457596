import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext'; // Import useAuth
import EndOfWeek from './EndOfWeek';
import axios from 'axios';
import LoginBase from './LoginBase';
import logoUrl from './logo.jpg';

const Home = () => {
    const { rootUrl } = require('../../config/config');
    const { isAuthenticated } = useAuth();
    const [successMessage, setSuccessMessage] = useState('');
    const [failMessage, setFailMessage] = useState('');
    const [drivers, setDrivers] = useState('');
    const [cars, setCars] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [topBalances, setTopBalances] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCars = async () => {
            setLoading(true);
            const token = localStorage.getItem('authToken') || 'default-token';
            try {
                const [carResponse, driverResponse] = await Promise.all([
                    axios.get(`${rootUrl}/cars`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(`${rootUrl}/drivers`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);

                setDrivers(driverResponse.data);

                const carData = carResponse.data;
                const mappedData = carData.map((item) => ({
                    plate_no: item.plate_no || 'No sender name',
                    bal: parseFloat(item.bal || '0.00'),
                }));

                setCars(mappedData);

                const total = mappedData.reduce((acc, car) => acc + car.bal, 0);
                setTotalAmount(total.toFixed(2));

                const topBalancesData = [...mappedData]
                    .sort((a, b) => b.bal - a.bal)
                    .slice(0, 3);
                setTopBalances(topBalancesData);
            } catch (error) {
                console.error('Error fetching cars:', error);
                
           
            }
        };

        fetchCars();
    }, []);

    useEffect(() => {
        if (successMessage || failMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setFailMessage('');
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, failMessage]);

    

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            {successMessage && (
                <div className="w-full px-4 py-2 rounded-md text-white bg-green-500 mb-4 text-center">
                    {successMessage}
                </div>
            )}
            {failMessage && (
                <div className="w-full px-4 py-2 rounded-md text-white bg-red-500 mb-4 text-center">
                    {failMessage}
                </div>
            )}
            {isAuthenticated ? (
                <>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4 w-full max-w-4xl">
                        <div className="bg-green-100 p-4 rounded-lg shadow">
                            <h3 className="text-lg font-semibold text-green-700">Total Money In</h3>
                            <p className='text-green-700'>{new Intl.NumberFormat('en-US').format(totalAmount)}</p>
                        </div>
                        <div className="bg-red-100 p-4 rounded-lg shadow">
                            <h3 className="text-lg font-semibold text-red-700">Total Money Out</h3>
                            <p className="text-red-700">--</p>
                        </div>
                        <div className="bg-blue-100 p-4 rounded-lg shadow">
                            <h3 className="text-lg font-semibold text-blue-700">Top Balances </h3>
                            <p className="text-blue-700">
                                {topBalances.length > 0 ? (
                                    topBalances.map((car, index) => (
                                        <div key={index}>
                                           < >{car.plate_no}</> : {new Intl.NumberFormat('en-US').format(car.bal)}
                                        </div>
                                    ))
                                ) : (
                                    <span>No data available</span>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="w-full max-w-md px-6 py-8 bg-white shadow-lg rounded-lg mt-6">
                        <div className="flex justify-center mb-6">
                            <img
                                src={logoUrl}
                                alt="COSMO FLEETS"
                                className="h-20 w-120 object-cover shadow-lg"
                            />
                        </div>
                        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">Cosmo Fleets!</h1>
                        <p className="text-lg text-center text-gray-600 mb-8">
                            Wheel to your <strong>Dreams</strong>.
                        </p>
                        <EndOfWeek
                            setSuccessMessage={setSuccessMessage}
                            setFailMessage={setFailMessage}
                        />
                    </div>
                </>
            ) : (
                <div className="w-full max-w-md px-6 py-8 bg-white shadow-lg rounded-lg">
                    <div className="flex justify-center mb-6">
                        <img
                            src={logoUrl}
                            alt="COSMO FLEETS"
                            className="h-20 w-120 object-cover shadow-lg"
                        />
                    </div>
                    <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">Cosmo Fleets!</h1>
                    <p className="text-lg text-center text-gray-600 mb-8">
                        Wheel to your <strong>Dreams</strong>.
                    </p>
                    <LoginBase />
                </div>
            )}
        </div>
    );
};

export default Home;
