import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/myTrack/AuthContext';
import ProtectedRoute from './components/myTrack/ProtectedRoute';
import MyTrackApp from './components/myTrack/MyTrack';
import PaymentPage from './components/myTrack/docNo';
import Home from './components/myTrack/home';
import CarPage from './components/myTrack/carpage';
import Transactions from './components/myTrack/transactions';
import Notifications from './components/myTrack/notifications';
import Header from './components/myTrack/header';
import CarTransactions from './components/myTrack/CarTransactions';
import MyCarsApp from './components/myTrack/MyCars';
import MyDriversApp from './components/myTrack/MyDrivers';
import Settings from './components/myTrack/settings';
import Login from './components/myTrack/login';
import Signup from './components/myTrack/signup';
import { FaBars, FaHome, FaEnvelope, FaCar, FaUsers, FaMoneyCheckAlt, FaChartBar, FaSignOutAlt, FaCog } from 'react-icons/fa';
import './index.css';
import logoUrl from './components/Assets/logo.jpg';
import { ContactProvider } from './components/myTrack/ContactContext';
import { ThemeProvider } from './components/myTrack/ThemeContext';

const App = () => (
  <AuthProvider>
    <MainApp />
  </AuthProvider>
);

const MainApp = () => {
  const { isAuthenticated, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  useEffect(() => {
    setIsSidebarOpen(isAuthenticated && window.innerWidth >= 768);
  }, [isAuthenticated]);

  const closeSidebar = () => {
    if (window.innerWidth < 768) setIsSidebarOpen(false);
  };

  const handleSettingsClick = () => {
    // Example: Redirect to the settings page
    window.location.href = '/cosmosettings';
  };

  return (
    <Router>
      <ThemeProvider>
        <ContactProvider>
          <div className="flex h-screen pt-10 mt-10"> 
          {isAuthenticated && <Header />}
            {/* Sidebar */}
            {isAuthenticated && (
              <div
                className={`fixed top-0 left-0 w-64 h-full bg-slate-400 transition-transform transform ${
                  isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                } md:translate-x-0 ease-in-out z-40`}
              >
                <div className="flex items-center justify-between p-4 mt-20">
                 {/*<img src={logoUrl} alt="Company Logo" className="w-20 h-20 rounded-full" />
                  <strong className="text-white text-lg font-semibold">COSMO FLEETS</strong>*/}
                </div>
                <nav>
                  <ul className="list-none m-0 p-0 mt-4">
                    <NavItem to="/" icon={<FaHome />} label="Home" closeSidebar={closeSidebar} />
                    <NavItem to="/mytrack" icon={<FaEnvelope />} label="Messages" closeSidebar={closeSidebar} />
                    <NavItem to="/cars" icon={<FaCar />} label="Cars" closeSidebar={closeSidebar} />
                    <NavItem to="/drivers" icon={<FaUsers />} label="Drivers" closeSidebar={closeSidebar} />
                    <NavItem to="/transactions" icon={<FaMoneyCheckAlt />} label="Transactions" closeSidebar={closeSidebar} />
                    <NavItem to="/cosmosettings" icon={<FaCog />} label="Settings" closeSidebar={closeSidebar} />
                    <li className="mt-4">
                      <button
                        onClick={() => {
                          logout();
                          closeSidebar();
                        }}
                        className="w-full text-white bg-red-600 hover:bg-red-700 p-2 rounded-md flex items-center space-x-2"
                      >
                        <FaSignOutAlt />
                        <span>Logout</span>
                      </button>
                    </li>
                  </ul>
                </nav>

                
              </div>
              
            )}

            {/* Toggle Button for Small Screens */}
            { isAuthenticated &&(
            <button
              onClick={toggleSidebar}
              className="fixed top-1 left-0  md:hidden z-50 text-white  p-2 rounded-md shadow-lg"
              aria-label="Toggle Sidebar"
            >
              <FaBars className="text-xl" />
            </button>
            )}
            

            {/* Main Content */}
            <div
                className={`flex-1 p-6 transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-0'} ${
                 isAuthenticated ? 'md:ml-64' : 'md:ml-0'
                }`}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/transactions/:plate_no" element={<CarTransactions />} />
                <Route path="/transactions/:plate_no/pay/:type/:doc_no" element={<PaymentPage />} />
                <Route path="/mytrack" element={<ProtectedRoute element={<MyTrackApp />} />} />
                <Route path="/cars" element={<ProtectedRoute element={<MyCarsApp />} />} />
                <Route path="/drivers" element={<ProtectedRoute element={<MyDriversApp />} />} />
                <Route path="/report" element={<ProtectedRoute element={<CarPage />} />} />
                <Route path="/notifications" element={<ProtectedRoute element={<Notifications />} />} />
                <Route path="/transactions" element={<ProtectedRoute element={<Transactions />} />} />
                <Route path="/cosmosettings" element={<ProtectedRoute element={<Settings />} />} />
              </Routes>
            </div>
          </div>
        </ContactProvider>
      </ThemeProvider>
    </Router>
  );
};

// NavItem component with closeSidebar function
const NavItem = ({ to, icon, label, closeSidebar }) => (
  <li>
    <Link
      to={to}
      onClick={closeSidebar}
      className="flex items-center text-white p-2 rounded-md hover:bg-blue-700 transition duration-300"
    >
      <span className="mr-3">{icon}</span>
      <span>{label}</span>
    </Link>
  </li>
);

export default App;
