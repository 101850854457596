import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ReportPage = () => {
  const { rootUrl } = require("../../config/config");
  const { plate_no } = useParams(); // Retrieve plate_no from URL params
  console.log("Plate Number:", plate_no); // Log the plate_no to debug
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateFilter, setDateFilter] = useState("week"); // Default to weekly
  const [selectedCar, setSelectedCar] = useState(null); // Default to all cars
  const [data, setData] = useState({
    cars: [],
    drivers: [],
    messages: [],
    profitBreakdown: [],
    statusDistribution: [],
    transactions: [], // Store transactions here
  });

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("authToken") || "default-token";

        const queryParams = new URLSearchParams({
          dateFilter,
          carPlate: selectedCar || "", // Ensure carPlate is either empty or the selected car plate
        }).toString();

        // Fetch the necessary data
        const [carsRes, driversRes, messagesRes] = await Promise.all([
          axios.get(`${rootUrl}/cars?${queryParams}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${rootUrl}/drivers?${queryParams}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${rootUrl}/messages?${queryParams}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        const cars = carsRes.data;
        const drivers = driversRes.data;
        const messages = messagesRes.data;

        // Ensure the plate_no exists and is not undefined
        const plate = plate_no || selectedCar;
        if (!plate) {
          setError("No car selected, and no plate number in URL.");
          setLoading(false);
          return;
        }

        // Fetch transactions for the selected car or all cars
        const transactions = [];
        if (plate) {
          const res = await axios.get(`${rootUrl}/cars/${plate}/trans`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          transactions.push(...res.data);
        } else {
          // Fetch transactions for all cars if no specific car is selected
          for (const car of cars) {
            const res = await axios.get(`${rootUrl}/cars/${car.plate_no}/trans`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            transactions.push(...res.data);
          }
        }

        // Process data for charts
        const profitBreakdown = cars.map((car) => ({
          category: car.plate_no || "Unknown",
          profit: car.income || 0,
        }));

        const statusDistribution = cars.reduce((acc, car) => {
          const status = car.status || "Unknown";
          acc[status] = (acc[status] || 0) + 1;
          return acc;
        }, {});

        setData({
          cars,
          drivers,
          messages,
          profitBreakdown,
          statusDistribution: Object.entries(statusDistribution).map(
            ([key, value]) => ({ status: key, count: value })
          ),
          transactions, // Store the transactions in state
        });

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err); // Debug log for error
        setError(err);
        setLoading(false);
      }
    };

    fetchReportData();
  }, [dateFilter, selectedCar, plate_no]);

  const getStatusColor = (status) => {
    const colorMapping = {
      Available: "#4CAF50",
      Assigned: "#2196F3",
      "Under Maintenance": "#FFBB28",
      Reserved: "#FFD700",
      "Out of Service": "#F44336",
      Retired: "#6A5ACD",
    };
    return colorMapping[status] || "#D3D3D3";
  };

  const grossIncome = data.cars.reduce((sum, car) => sum + (car.income || 0), 0);
  const netIncome = grossIncome - data.cars.reduce((sum, car) => sum + (car.expenses || 0), 0);

  if (loading) return <p className="text-center text-lg">Loading report...</p>;
  if (error) return <p className="text-center text-lg text-red-600">Error: {error.message}</p>;

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-6">
      {/* Header Section */}
      <header className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-800">Fleet Management Report</h1>
        <p className="text-lg text-gray-600">
          Explore fleet performance, driver distribution, and profitability insights.
        </p>
      </header>

      {/* Filter Controls */}
      <section className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <label className="mr-2 font-semibold text-gray-700">Filter by:</label>
            <select
              className="p-2 border rounded"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            >
              <option value="week">Weekly</option>
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </select>
          </div>
          <div>
            <label className="mr-2 font-semibold text-gray-700">Car:</label>
            <select
              className="p-2 border rounded"
              value={selectedCar || ""}
              onChange={(e) => setSelectedCar(e.target.value || null)}
            >
              <option value="">All Cars</option>
              {data.cars.map((car) => (
                <option key={car.plate_no} value={car.plate_no}>
                  {car.plate_no}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>

      {/* Print and Share Buttons */}
      <section className="mb-6 text-right">
        <button
          className="px-4 py-2 bg-blue-600 text-white rounded shadow-md mr-2"
          onClick={() => window.print()}
        >
          Print Report
        </button>
        <button
          className="px-4 py-2 bg-green-600 text-white rounded shadow-md"
          onClick={() => {
            const reportUrl = `${window.location.origin}/reports?dateFilter=${dateFilter}&carPlate=${selectedCar || ''}`;
            navigator.share
              ? navigator.share({
                  title: "Fleet Report",
                  text: "Check out this fleet report.",
                  url: reportUrl,
                })
              : alert("Sharing not supported on this browser.");
          }}
        >
          Share Report
        </button>
      </section>

      {/* Key Metrics Summary */}
      <section className="mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-6 text-center">
          {!selectedCar && (
            <div className="bg-white shadow-md p-6 rounded">
              <h3 className="text-xl font-semibold text-gray-700">Total Cars</h3>
              <p className="text-3xl font-bold text-blue-600">{data.cars.length}</p>
            </div>
          )}
          {!selectedCar && (
            <div className="bg-white shadow-md p-6 rounded">
              <h3 className="text-xl font-semibold text-gray-700">Total Drivers</h3>
              <p className="text-3xl font-bold text-green-600">{data.drivers.length}</p>
            </div>
          )}

          <div className="bg-white shadow-md p-6 rounded">
            <h3 className="text-xl font-semibold text-gray-700">
              {selectedCar ? `Gross Income (Car: ${selectedCar})` : "Gross Income"}
            </h3>
            <p className="text-3xl font-bold text-green-600">
              ${selectedCar
                ? data.cars
                    .filter((car) => car.plate_no === selectedCar)
                    .reduce((sum, car) => sum + (car.income || 0), 0)
                : data.cars.reduce((sum, car) => sum + (car.income || 0), 0)}
            </p>
          </div>
          <div className="bg-white shadow-md p-6 rounded">
            <h3 className="text-xl font-semibold text-gray-700">
              {selectedCar ? `Net Income (Car: ${selectedCar})` : "Net Income"}
            </h3>
            <p className="text-3xl font-bold text-green-600">
              ${selectedCar
                ? data.cars
                    .filter((car) => car.plate_no === selectedCar)
                    .reduce((sum, car) => sum + ((car.income || 0) - (car.expenses || 0)), 0)
                : data.cars.reduce(
                    (sum, car) => sum + ((car.income || 0) - (car.expenses || 0)),
                    0
                  )}
            </p>
          </div>
        </div>
      </section>

      {/* Visual Data Insights */}
      <section className="mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-6">
          <div className="bg-white shadow-md p-6 rounded">
            <h3 className="text-xl font-semibold text-gray-700 text-center">Profit Breakdown</h3>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={data.profitBreakdown}>
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="profit" fill="#3CB371" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="bg-white shadow-md p-6 rounded">
            <h3 className="text-xl font-semibold text-gray-700 text-center">Status Distribution</h3>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={data.statusDistribution}
                  dataKey="count"
                  nameKey="status"
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {data.statusDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getStatusColor(entry.status)} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </section>

      {/* Transaction Data */}
      <section className="mb-12">
        <h3 className="text-2xl font-semibold text-gray-700 mb-4">
          Transactions for Car {selectedCar ? `(${selectedCar})` : "All Cars"}
        </h3>
        {data.transactions.length === 0 ? (
          <p>No transactions found for car with plate number {selectedCar || "all cars"} in the specified date range.</p>
        ) : (
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="border-b p-4">Transaction ID</th>
                <th className="border-b p-4">Amount</th>
                <th className="border-b p-4">Date</th>
              </tr>
            </thead>
            <tbody>
              {data.transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="border-b p-4">{transaction.id}</td>
                  <td className="border-b p-4">${transaction.amount}</td>
                  <td className="border-b p-4">{new Date(transaction.date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </div>
  );
};

export default ReportPage;
