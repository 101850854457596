import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faArrowLeft, faShareAlt } from '@fortawesome/free-solid-svg-icons';


const { rootUrl } = require('../../config/config');

const CarTransactions = () => {
    const { plate_no } = useParams();
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSharing, setIsSharing] = useState(false);
    const [carDetails, setCarDetails] = useState([]);
    const [balances, setBalances] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState(() => new Date().toISOString().split('T')[0]);

    useEffect(() => {
        const fetchCarData = async () => {
            try {
                const token = localStorage.getItem('authToken') || 'default-token';
                const [transResponse, balResponse, carResponse] = await Promise.all([
                    axios.get(`${rootUrl}/cars/${plate_no}/trans`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`${rootUrl}/cars/${plate_no}`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`${rootUrl}/cars`, { headers: { Authorization: `Bearer ${token}` } }),
                ]);

                setTransactions(transResponse.data);
                setBalances(balResponse.data);
                setCarDetails(carResponse.data.find((c) => c.plate_no === plate_no));
                console.log('abt actual balance', carResponse);
            } catch (error) {
                setError(error.response?.data?.message || 'Error fetching data. Please try again later.');
                console.error('Error fetching car data:', error);
                
            } finally {
                setLoading(false);
            }
        };

        fetchCarData();
    }, [plate_no]);

    useEffect(() => {
        
        const storedDateFrom = localStorage.getItem('date_from');
        if (storedDateFrom) {
            setDateFrom(storedDateFrom);  
        }
    }, []);

    const handleDateFromChange = (value) => {
        setDateFrom(value);
};


useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        navigate('/login'); // Redirect to login if no token is found
    }
}, [navigate]);


    const handleDateToChange = (value) => {
        setDateTo(value);
    };

    const filteredTransactions = transactions.filter(transaction => {
        const transactionDate = new Date(transaction.date).setHours(0, 0, 0, 0);
        const from = dateFrom ? new Date(dateFrom).setHours(0, 0, 0, 0) : null;
        const to = dateTo ? new Date(dateTo).setHours(0, 0, 0, 0) : null;
    
        const isWithinDateRange = (!from || transactionDate >= from) && (!to || transactionDate <= to);
        return isWithinDateRange;
    });

    const handleShare = async () => {
        if (isSharing) return; // Prevent multiple share attempts
    
        setIsSharing(true); // Set sharing state to true
        try {
          if (navigator.share) {
            await navigator.share({
              title: 'Check this out!',
              text: 'Here is something interesting.',
              url: window.location.href,
            });
            console.log('Content shared successfully!');
          } else {
            alert('Sharing is not supported in your browser.');
          }
        } catch (error) {
          if (error.name === 'AbortError') {
            console.error('Share aborted by the user.');
          } else {
            console.error('Error sharing content:', error);
          }
        } finally {
          setIsSharing(false); // Reset sharing state
        }
      };

      const formatMoney = (value) => {
        if (value === null || value === undefined || value === '-') return '-';
        return new Intl.NumberFormat('en-US').format(value);
      };
    
    

    return (
        <div className="max-w-7xl mx-auto p-6 space-y-8">
  {/* Buttons Section */}
  <div className="flex justify-between items-center">
    {/* Back Button */}
    <button
      onClick={() => navigate(-1)}
      className="bg-slate-400 px-2 py-1 rounded-lg"
    >
      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
      Back
    </button>

    {/* Share Button */}
    <button
      onClick={handleShare}
      disabled={isSharing}
      className={`bg-green-400 px-2 py-1 rounded-lg ${
        isSharing ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      <FontAwesomeIcon icon={faShareAlt} className="mr-2" />
      {isSharing ? 'Sharing...' : 'Share'}
    </button>
  </div>
            {/* Car Details Section */}
            {carDetails && (
                <div className="bg-white p-8 rounded-lg shadow-lg space-y-6">
                    <h2 className="text-3xl font-semibold text-gray-800">Car Details</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                        {/* Car Information */}
                        <div className="space-y-4">
                            <h3 className="text-xl font-medium text-gray-700">Car Info</h3>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Plate No:</span>
                                <span>{carDetails.plate_no}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Make:</span>
                                <span>{carDetails.make}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Model:</span>
                                <span>{carDetails.model}</span>
                            </div>
                        </div>

                      
                        {/* Miscellaneous Information */}
                        <div className="space-y-4">
                            <h3 className="text-xl font-medium text-gray-700">Miscellaneous</h3>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Driver Name:</span>
                                <span>{carDetails.driver_name}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Insurance Exp:</span>
                                <span
                                    className={`${
                                        carDetails.insurer_expiry &&
                                        new Date(carDetails.insurer_expiry) <= new Date(new Date().setMonth(new Date().getMonth() + 1))
                                            ? 'text-red-600'
                                            : 'text-gray-600'
                                    }`}
                                >
                                    {carDetails.insurer_expiry || 'N/A'}
                                </span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Drive Link:</span>
                                <a
                                    href={carDetails.drive_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:underline cursor-pointer"
                                >
                                    Open Link
                                </a>
                            </div>
                            
                        </div>
                          {/* Financial Information */}
                          <div className="space-y-4">
                            <h3 className="text-xl font-medium text-gray-700">Financial Info</h3>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Balance/Bf:</span>
                                <span className='text-right'>{formatMoney(balances.bal_bf)}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium ">Balance:</span>
                                <span className='text-right'>{formatMoney(balances.bal)}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium ">Actual Balance:</span>
                                <span className='text-right'>{formatMoney(balances.actual_bal)}</span>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center text-gray-600">
                                <span className="w-32 font-medium">Weekly Amt:</span>
                                <span className='text-right'>{formatMoney(carDetails.weekly_amount)}</span>
                            </div>
                        </div>
                        

                    </div>
                </div>
            )}

            {/* Transactions Table */}
            <div className="space-y-4">
                <h1 className="text-3xl font-semibold text-gray-800">Transactions for Car {plate_no}</h1>
                <div className="flex items-center">
                    <label className="mr-2">Date From:</label>
                    <input
                        type="date"
                        className="border rounded-xl px-2 py-1 mr-2 bg-slate-300"
                        value={dateFrom}
                        onChange={(e) => handleDateFromChange(e.target.value)}
                    />
                    <label className="mr-2">Date To:</label>
                    <input
                        type="date"
                        className="border rounded-xl px-2 py-1 mr-2 bg-slate-300"
                        value={dateTo}
                        onChange={(e) => handleDateToChange(e.target.value)}
                    />
                </div>

                {loading ? (
                    <div className="flex justify-center items-center space-x-2">
                        <FontAwesomeIcon icon={faSpinner} spin className="text-2xl text-blue-500" />
                        <span className="text-gray-500">Loading transactions...</span>
                    </div>
                ) : error ? (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md shadow-md">
                        <p>{error}</p>
                    </div>
                ) : (
                    <div>
                    <div className="hidden sm:block bg-white shadow-lg rounded-lg border border-gray-200"
                    >
                        {filteredTransactions.length > 0 ? (
                            <table className="min-w-full table-auto">
                                <thead className="bg-slate-800 text-white">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Date</th>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Doc No</th>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Reference</th>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Type</th>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">In</th>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Out</th>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Post Balance</th>
                                        
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Input By</th>
                                        <th className="px-6 py-3 text-left text-sm font-semibold">Description</th>
                                        
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700">
                                    {filteredTransactions
                                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                                        .map((trans, index) => (
                                            <tr
                                                key={trans.id}
                                                className={`hover:bg-gray-100 ${
                                                    index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                                                }`}
                                            >
                                                <td className="px-6 py-4">{new Date(trans.date).toLocaleDateString('en-GB')}</td>
                                                <td className="px-6 py-4"
                                                href={carDetails.drive_link}
                                                >
                                                <Link
                                                   to={`pay/${trans.type}/${trans.doc_no}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   className='text-blue-600 hover:underline cursor-pointer'
>
                                                    {trans.doc_no}
                                                </Link>
                                                </td>
                                                <td className="px-6 py-4">{trans.your_ref}</td>
                                                <td className="px-6 py-4">{trans.type}</td>
                                                <td className="px-6 text-right py-4">{formatMoney(trans.credit)}</td>
                                                <td className="px-6 text-right py-4">{formatMoney(trans.debit)}</td>
                                                <td className="px-6 text-right py-4">{formatMoney(trans.post_balance)}</td> 
                                               
                                                <td className="px-6 py-4">{trans.input_by}</td>
                                                <td className="px-6 py-4">{trans.notes}</td>
                                                
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            
                        ) : (
                            <p className="text-center p-4">No transactions available for the selected period.</p>
                        )}
                        </div>
                    <div className="sm:hidden space-y-4">
                         {filteredTransactions.map((trans) => (
                            <div key={trans.id} className="bg-white shadow-lg rounded-lg p-4 border">
                                <p>
                                    <strong>Date:</strong> {new Date(trans.date).toLocaleDateString()}
                                </p>
                                <p>
                                    <strong>Reference:</strong> {trans.your_ref}
                                </p>
                                <p>
                                    <strong>Type:</strong> {trans.type}
                                </p>
                                <p>
                                    <strong>In:</strong> {formatMoney(trans.credit)}
                                </p>
                                <p>
                                    <strong>Out:</strong> {formatMoney(trans.debit)}
                                </p>
                                <p>
                                    <strong>Post Balance:</strong> {formatMoney(trans.post_balance)}
                                </p>
                                <p>
                                    <strong>Input By:</strong> {trans.input_by}
                                </p>
                                <p>
                                    <strong>Description:</strong> {trans.notes}
                                </p>
                                </div>

                            ))}
                                
 
                    </div>
                    

                    </div>
                )}
            </div>
        </div>
    );
};

export default CarTransactions;
