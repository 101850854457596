import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Adjust the path as needed

const Settings = () => {
  const { user } = useAuth(); // Fetch user data from AuthContext
  const [password, setPassword] = useState({ newPassword: '', confirmPassword: '' });
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [weeklyAmount, setWeeklyAmount] = useState(0); // For "Danger Zone"

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword((prev) => ({ ...prev, [name]: value }));
    if (name === 'confirmPassword') {
      setIsPasswordMatch(value === password.newPassword);
    }
  };

  const saveSettings = () => {
    alert('Settings saved successfully!');
    console.log('Updated settings:', { password, weeklyAmount });
  };

  return (
    <div className="settings-page max-w-6xl mx-auto p-6 mt-10 space-y-8">
      {/* Profile Header */}
      <div className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 text-white p-8 rounded-lg shadow-lg">
        <div className="flex items-center space-x-6">
          <div>
            <h1 className="text-3xl font-bold">{user?.username || 'Loading...'}</h1>
            <p className="text-gray-200 text-lg">Manage your account settings</p>
          </div>
        </div>
      </div>

      {/* Account Details Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 border-t-4 border-purple-500">
        <h2 className="text-xl font-semibold mb-4 text-purple-600">Account Details</h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 text-sm mb-1">Name</label>
            <input
              type="text"
              value={user?.username || ''}
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700 cursor-not-allowed"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-600 text-sm mb-1">Email</label>
            <input
              type="email"
              value={user?.email || ''}
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700 cursor-not-allowed"
              readOnly
            />
          </div>
        </div>
      </div>

      {/* Password Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 border-t-4 border-blue-500">
        <h2 className="text-xl font-semibold mb-4 text-blue-600">Change Password</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-600 text-sm mb-1">New Password</label>
            <input
              type="password"
              name="newPassword"
              placeholder="Enter new password"
              value={password.newPassword}
              onChange={handlePasswordChange}
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700"
            />
          </div>
          <div>
            <label className="block text-gray-600 text-sm mb-1">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm new password"
              value={password.confirmPassword}
              onChange={handlePasswordChange}
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700"
            />
            {!isPasswordMatch && (
              <p className="text-red-500 text-sm mt-2">Passwords do not match</p>
            )}
          </div>
        </div>
      </div>

      {/* Financial Summary Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 border-t-4 border-pink-500">
        <h2 className="text-xl font-semibold mb-4 text-pink-600">Financial Summary</h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 text-sm mb-1">Weekly Expected Amount</label>
            <input
              type="text"
              value="5000" // Replace with API value
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700 cursor-not-allowed"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-600 text-sm mb-1">Total Received</label>
            <input
              type="text"
              value="12345" // Replace with API value
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700 cursor-not-allowed"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-600 text-sm mb-1">Total Expenses</label>
            <input
              type="text"
              value="6789" // Replace with API value
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700 cursor-not-allowed"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-600 text-sm mb-1">Total Money Loss</label>
            <input
              type="text"
              value="2345" // Replace with API value
              className="w-full border rounded-lg p-3 bg-gray-50 text-gray-700 cursor-not-allowed"
              readOnly
            />
          </div>
        </div>
      </div>

      {/* Danger Zone */}
      <div className="bg-red-50 rounded-lg shadow-lg p-6 border-t-4 border-red-500">
        <h2 className="text-xl font-semibold mb-4 text-red-600">Danger Zone</h2>
        <div className="space-y-4">
          <label className="block text-gray-600 text-sm mb-1">
            Change Weekly Expected Amount
          </label>
          <input
            type="number"
            value={weeklyAmount}
            onChange={(e) => setWeeklyAmount(e.target.value)}
            className="w-full border rounded-lg p-3 bg-white text-gray-700"
          />
        </div>
      </div>

      {/* Save Button */}
      <div className="text-right">
        <button
          onClick={saveSettings}
          className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-2 px-8 rounded-lg shadow-lg hover:opacity-90"
        >
          Save Settings
        </button>
      </div>
    </div>
  );
};

export default Settings;
