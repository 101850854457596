import React from 'react'
import { Link } from 'react-router-dom';

function LoginBase() {
  return (
    <div className="flex space-x-4 justify-center">
                        <Link
                            to="/login"
                            className="bg-blue-600 text-white font-semibold px-6 py-2 rounded-md hover:bg-blue-700 transition"
                        >
                            Login
                        </Link>
                        
                    </div>
  )
}

export default LoginBase